// Import libs
import React from "react";

// Import shared components
import {
	Cover,
	PostContainer,
	Paragraph
} from "@windish/shared";

// Import local components
import Seo from "../../components/Seo";
import Layout from "../../components/Layout";
import Contact from "../../components/Contact";

// Import page-specific CSS
import "./index.css"

// Page constants
const pageTitle = "About";
const pageDescription = "Designer & mentor, working to humanize data";

// Page
export default function About() {

	// Render page
	return (
		<Layout pageTitle={pageTitle}>
			<Cover
				heading={pageDescription}
			/>
			<PostContainer>
				<Paragraph>Having a mixed background in computer engineering and visual communication has allowed me to explore their overlaps in information management and human/computer interaction. My endeavours have repeatedly led to positive business outcomes, great user feedback, and professional recognition in design, journalism, and statistics.</Paragraph>
				<Paragraph>Surprisingly, I began my professional path in the newsroom, pushing at the limits of the faltering industry for almost a decade. After a quick detour through marketing, I eventually came back to my original passion: software development.</Paragraph>
				<Paragraph>Along this journey, I have met exceptional people, contributing in immeasurable ways: giving me opportunities to prove myself, mentoring me out of foolish mistakes, trusting me to lead our joint initiatives. Likewise, I support the up-and-coming talent, also sharing my experience through writing, lectures, and workshops.</Paragraph>
				<Paragraph>Over time, I have acquired an extensive network of contacts: forward-thinking organizations on the one hand and world-class talent on the other. They often turn to me for career advice or help with recruitment. I welcome both and care a lot about each finding the other. A practice rests or falls on the community of its practitioners.</Paragraph>
				<Paragraph>I currently reside in Ljubljana, Slovenia's capital city, with my partner Katja, daughter Mila, and our cats Črka and Pika. When I am not climbing, mountain-biking or slacklining, I like to tinker with electronics, build Legos, make anything with my hands. It keeps me calm and my body sharp.</Paragraph>
			</PostContainer>
			<Contact />
		</Layout>
	);

}

// Page metadata
export const Head = () => (
	<Seo
		title={pageTitle}
		description={pageDescription}
	>
		<script type="application/ld+json">
			{`
        {
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": "${pageTitle}",
          "description": "${pageDescription}",
          "url": "https://vindis.io/about",
          "copyrightHolder": "Aljaž Vindiš",
          "copyrightYear": 2022,
					"author": {
						"@type": "Person",
						"name": "Aljaž Vindiš",
						"url": "https://vindis.io/"
					},
					"publisher": {
						"@type": "Person",
						"name": "Aljaž Vindiš",
						"url": "https://vindis.io/"
					}
        }
      `}
		</script>
	</Seo>
)
